import { EmailClientTypes } from './setupEnums';
import {
    AgentProfileModel,
    AgentWithSnippetModel,
    AutocompleteSuggestion,
    ImportFromEmailClientModel,
    ImportUserModel,
    NewAgentModel,
    ReviewSiteModel,
    ReviewSiteOptionModel,
    SuggestionRequest, TemplateFeedbackOptionModel,
    TemplateModel,
    TextTemplateModel, TextTemplateUpdateModel
} from './SetupModels';
import { deprecated } from "typesafe-actions";
import {ReviewSite} from "./SetupPageData";
import {Organization} from "../shared/SharedData";




const { createStandardAction } = deprecated;

export const getAgents = createStandardAction('setup/GET_AGENTS')();
export const editAgent = createStandardAction('setup/EDIT_AGENT')<AgentProfileModel>();
export const addAgent = createStandardAction('setup/ADD_AGENT')<NewAgentModel>();

export const selectEmailClient = createStandardAction('setup/SELECT_EMAIL_CLIENT')<EmailClientTypes>();
export const getTemplate = createStandardAction('setup/GET_TEMPLATE')();
export const getTextTemplate = createStandardAction('setup/GET_TEXT_TEMPLATE')();
export const updateTemplate = createStandardAction('setup/UPDATE_TEMPLATE')<TemplateModel>();
export const updateTextTemplate = createStandardAction('setup/UPDATE_TEXT_TEMPLATE')<TextTemplateUpdateModel>();
export const updateTemplateFeedbackOption = createStandardAction('setup/UPDATE_TEMPLATE_FEEDBACK_OPTION')<TemplateFeedbackOptionModel>();
export const importCSV = createStandardAction('setup/UPLOAD_CSV')<any>();
export const importFromEmailClient = createStandardAction('setup/IMPORT_FROM_EMAIL_CLIENT')<ImportFromEmailClientModel>();
export const getAgentsWithSnippetByOrg = createStandardAction('setup/GET_AGENTS_WITH_SNIPPET_BY_ORG')();
export const emailSurveyToAgent = createStandardAction('setup/EMAIL_SURVEY_TO_AGENT')<number>();
export const getAgentSnippet = createStandardAction('setup/GET_AGENT_SNIPPET')<string>();

export const getSuggestions = createStandardAction('setup/GET_SUGGESTIONS')<SuggestionRequest>();
export const getSuggestionsCompleted = createStandardAction('setup/GET_SUGGESTIONS_COMPLETED')<AutocompleteSuggestion[]>();
export const toggleReviewSiteIsActive = createStandardAction('setup/TOGGLE_REVIEW_SITE_ACTIVE')<ReviewSite>();
export const toggleReviewSiteIsActiveCompleted = createStandardAction('setup/TOGGLE_REVIEW_SITE_ACTIVE_COMPLETED')<ReviewSite>();
export const toggleSurveyFormIsActive = createStandardAction('setup/TOGGLE_SURVEY_FORM_ACTIVE')<Organization>();
export const toggleSurveyFormIsActiveCompleted = createStandardAction('setup/TOGGLE_SURVEY_FORM_ACTIVE_COMPLETED')<boolean>();
export const addReviewSite = createStandardAction('setup/ADD_REVIEWSITE')<ReviewSiteModel>();
export const addReviewSiteCompleted = createStandardAction('setup/ADD_REVIEWSITE_COMPLETED')<ReviewSiteModel>();
export const deleteReviewSite = createStandardAction('setup/DELETE_REVIEWSITE')<number>();
export const setReviewSiteAllocationPercentage = createStandardAction('setup/SET_REVIEWSITE_ALLOCATION_PERCENTAGE')<ReviewSiteModel[] | null>();
export const deleteReviewSiteCompleted = createStandardAction('setup/DELETE_REVIEWSITE_COMPLETED')<number>();
export const getReviewSites = createStandardAction('setup/GET_REVIEWSITES')();
export const getReviewSitesCompleted = createStandardAction('setup/GET_REVIEWSITES_COMPLETED')<ReviewSiteModel[]>();
export const getReviewSiteOptions = createStandardAction('setup/GET_REVIEWSITE_OPTIONS')();
export const getReviewSiteOptionsCompleted = createStandardAction('setup/GET_REVIEWSITE_OPTIONS_COMPLETED')<ReviewSiteOptionModel[]>(); // FIXME - CORRECT TYPE?


export const getAgentsCompleted = createStandardAction('setup/GET_AGENTS_COMPLETED')<AgentProfileModel[]>();
export const editAgentCompleted = createStandardAction('setup/EDIT_AGENT_COMPLETED')<AgentProfileModel>();
export const addAgentCompleted = createStandardAction('setup/ADD_AGENT_COMPLETED')<AgentProfileModel>();
// splitting the sliders into their own actions to delay the api call
export const updateTemplateTitleSpacing = createStandardAction('setup/UPDATE_TEMPLATE_TITLE_SPACING')<{value: number, id: number}>();
export const updateTemplateIconSize = createStandardAction('setup/UPDATE_TEMPLATE_ICON_SIZE')<number>();
export const updateTemplateActionSpacing = createStandardAction('setup/UPDATE_TEMPLATE_ACTION_SPACING')<{value: number, id: number}>();

export const updateTextTemplateTitleColour = createStandardAction('setup/UPDATE_TEXT_TEMPLATE_TITLE_COLOUR')<string>();
export const updateTextTemplateActionCount = createStandardAction('setup/UPDATE_TEXT_TEMPLATE_ACTION_COUNT')<{surveySetId: number, actionCount: number}>();
export const updateTextTemplateFontSize = createStandardAction('setup/UPDATE_TEXT_TEMPLATE_FONT_SIZE')<number>();
export const updateTextTemplateFontFamily = createStandardAction('setup/UPDATE_TEXT_TEMPLATE_FONT_FAMILY')<number>();
export const updateTextTemplateActionFontSize = createStandardAction('setup/UPDATE_TEXT_TEMPLATE_ACTION_FONT_SIZE')<number>();
export const updateTextTemplateTitle = createStandardAction('setup/UPDATE_TEXT_TEMPLATE_TITLE')<string>();
export const updateTextTemplateLanguage = createStandardAction('setup/UPDATE_TEXT_TEMPLATE_LANGUAGE')<number>();

export const updateTemplateCompleted = createStandardAction('setup/UPDATE_TEMPLATE_COMPLETED')<TemplateModel>();
export const updateTextTemplateCompleted = createStandardAction('setup/UPDATE_TEXT_TEMPLATE_COMPLETED')<TextTemplateModel>();
export const getTemplateCompleted = createStandardAction('setup/GET_TEMPLATE_COMPLETED')<TemplateModel>();
export const getTextTemplateCompleted = createStandardAction('setup/GET_TEXT_TEMPLATE_COMPLETED')<TextTemplateModel>()
export const importCompleted = createStandardAction('setup/IMPORT_COMPLETED')<ImportUserModel[]>();
export const getAgentsWithSnippetByOrgCompleted = createStandardAction('setup/GET_AGENTS_WITH_SNIPPET_BY_ORG_COMPLETED')<AgentWithSnippetModel[]>();

export const changeSaveTemplateFlag = createStandardAction('setup/SAVETEMPLATE_FLAG_CHANGED')<boolean>();
export const changeCopySnippetFlag = createStandardAction('setup/COPYSNIPPET_FLAG_CHANGED')<boolean>();
export const clearImportResult = createStandardAction('setup/CLEAR_IMPORT_RESULT')();

export const setAlertNotification = createStandardAction("setup/SET_ALERT_NOTIFICATION")<boolean>();

export const updateSurveySetType = createStandardAction('setup/UPDATE_SURVEY_SET_TYPE')<number>();
export const getPreviewLink = createStandardAction('setup/GET_PREVIEW_LINK')();
export const getPreviewLinkCompleted = createStandardAction('setup/GET_PREVIEW_LINK_COMPLETED')<string>();


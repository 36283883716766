import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as sharedSelectors from "../../shared/sharedSelectors";
import * as setupSelectors from "../setupSelectors";

import { redirect } from "../../shared/router/routerActions";
import {
  addReviewSite,
  deleteReviewSite,
  changeSaveTemplateFlag,
  updateTemplateFeedbackOption,
  getTemplate,
  getSuggestions,
  toggleReviewSiteIsActive,
  toggleSurveyFormIsActive,
  getReviewSites,
  getReviewSiteOptions,
  setAlertNotification,
  setReviewSiteAllocationPercentage,
  getPreviewLink
} from "../setupActions";

import { ApplicationState } from "../../ApplicationState";
import { getAllReviews } from "../../ReviewsPage/reviewsActions";
import * as selectors from "../../ReviewsPage/reviewsSelectors";
import ReviewsPage from "./ReviewsPage";

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  return {
    profile: sharedSelectors.profileSelector(state),
    reviewSites: setupSelectors.reviewSiteSelector(state),
    currentTemplate: setupSelectors.templateSelector(state),
    reviewSiteOptions: setupSelectors.reviewSiteOptionsSelector(state),
    reviews: selectors.reviewsSelector(state),
    suggestions: setupSelectors.suggestionsSelector(state),
    isSaved: setupSelectors.savedTemplateSelector(state),
    previewLinkUrl: setupSelectors.previewLinkSelector(state)
  };
};

const mapDispatchToProps = {
  redirect,
  addReviewSite,
  deleteReviewSite,
  getReviewSites,
  getReviewSiteOptions,
  updateTemplateFeedbackOption,
  getTemplate,
  getAllReviews,
  changeSaveTemplateFlag,
  getSuggestions,
  toggleReviewSiteIsActive,
  toggleSurveyFormIsActive,
  setAlertNotification,
  setReviewSiteAllocationPercentage,
  getPreviewLink
};

const Reviews = connect(mapStateToProps, mapDispatchToProps)(ReviewsPage);

export default Reviews;
